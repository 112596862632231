<template>
  <v-row>
    <v-col cols="12">
      <v-menu ref="date-range-picker-menu" v-model="menu"
        :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" offset-y min-width="auto">

        <template v-slot:activator="{on, attrs}">
          <v-text-field :dense="dense" outlined v-on="on"
            :value="dateRangeText" :label="$t('comps.dateRangePicker.dateRange')"
            prepend-icon="mdi-calendar-outline" readonly hide-details>
          </v-text-field>
        </template>

        <v-date-picker range v-model="dates" @change="onDateRangeChanged">
          <v-spacer></v-spacer>
            <v-btn v-if="showClearBtn"
              text color="primary" @click.stop="onDateRangeCleared">Clear
            </v-btn>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="onDateRangeChanged">
              OK
            </v-btn>
        </v-date-picker>

      </v-menu>
    </v-col>

    <!-- Shortcut buttons -->
    <v-col cols="12" class="d-flex justify-center">
      <v-btn outlined rounded small color="grey darken-1" class="ma-2" @click="applyShortcut('this-month')">
        {{ $t('comps.dateRangePicker.thisMonth') }}
      </v-btn>
      <v-btn outlined rounded small color="grey darken-1" class="ma-2" @click="applyShortcut('last-week')">
        {{ $t('comps.dateRangePicker.lastWeek') }}
      </v-btn>
      <v-btn outlined rounded small color="grey darken-1" class="ma-2" @click="applyShortcut('this-week')">
        {{ $t('comps.dateRangePicker.thisWeek') }}
      </v-btn>
      <v-btn outlined rounded small color="grey darken-1" class="ma-2" @click="applyShortcut('yesterday')">
        {{ $t('comps.dateRangePicker.yesterday') }}
      </v-btn>
      <v-btn outlined rounded small color="grey darken-1" class="ma-2" @click="applyShortcut('today')">
        {{ $t('comps.dateRangePicker.today') }}
      </v-btn>
    </v-col>
  </v-row>
  
</template>

<script>
import moment from 'moment';

export default {
  name: 'DateRangePicker',
  props: {
    dense: Boolean,
    defDates: {
      type: Array,
      default: () => [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ]
    },
    showClearBtn: {
      type: Boolean,
      default: false
    }
  },

  mounted() {

  },

  methods: {
    onDateRangeChanged() {
      if (moment(this.dates[0]).isAfter(moment(this.dates[1]))) {
        console.error(`'from' is after 'to'. This is not okay!!`);
        this.$notify({
          text: `Date "from" must be before date "to".`,
          type: 'error'
        });
        return;
      }

      this.$refs['date-range-picker-menu'].save(this.dates);
      this.$emit('update', this.dates);
      this.menu = false;
    },

    onDateRangeCleared() {
      this.dates = [];
      this.$refs['date-range-picker-menu'].save(this.dates);
      this.$emit('update', this.dates);
      // this.menu = false;
    },

    applyShortcut(shortcut) {
      switch (shortcut) {
        case 'today': {
          this.dates = [
            moment().startOf('day').format('YYYY-MM-DD'),
            moment().endOf('day').format('YYYY-MM-DD')
          ];
          break;
        }
        case 'yesterday': {
          this.dates = [
            moment().add(-1, 'days').startOf('day').format('YYYY-MM-DD'),
            moment().add(-1, 'days').endOf('day').format('YYYY-MM-DD')
          ];
          break;
        }
        case 'this-month': {
          this.dates = [
            moment().startOf('month').format('YYYY-MM-DD'),
            moment().endOf('month').format('YYYY-MM-DD')
          ];
          break;
        }
        case 'this-week': {
          this.dates = [
            moment().startOf('isoweek').format('YYYY-MM-DD'),
            moment().endOf('isoweek').format('YYYY-MM-DD')
          ];
          break;
        }
        case 'last-week': {
          this.dates = [
            moment().startOf('isoweek').subtract(1, 'w').format('YYYY-MM-DD'),
            moment().endOf('isoweek').subtract(1, 'w').format('YYYY-MM-DD')
          ];
          break;
        }
      }
      this.onDateRangeChanged();
    }
  },

  data() {
    return {
      menu: false,
      dates: this.defDates,
    };
  },

  computed: {
    dateRangeText() {
      if (this.dates.length) {
        return `${this.dates[0]} ~ ${this.dates[1]}`;
      } else {
        return ''
      }
      
    }
  },

  watch: {

  }
}
</script>